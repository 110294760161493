.App {
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Sections {
  display: flex;
  flex-wrap: wrap;
}

.Section {
  padding: 10px;
  flex: 1 1 45%; 
  min-width: 0; 
}

pre {
  font-size: 12px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.App-link {
  color: #61dafb;
}
